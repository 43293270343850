<template lang="pug">
router-view
</template>

<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'trade-objects',
});
</script>

<style lang="stylus"></style>
